<template>
    <div v-if="preloader === true">
        <template>
        <v-dialog v-model="preloader" hide-overlay persistent width="300">
            <v-progress-linear indeterminate></v-progress-linear>
        </v-dialog>
        </template>
    </div>

    <div v-else>
        <div v-if="displayNoData" class="mainDiv">
            <v-card 
                class="card"  flat color="transparent">
                <v-card-title>
                    No Demo Data Available
                </v-card-title>      
            </v-card>
        </div>
        <div class="w-100" v-else>
            <v-card height="64px" width="100%"
                class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
                style="position:fixed; z-index:200; top:0; width: 98%; padding-top: 16px;">

                <v-card-title class="pl-0 ml-0">
                    <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/campaign')">
                        Campaigns
                    </p>
                    <v-icon class="breadcrumb-arrow-unselected"
                        style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                    <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="goBack">
                        {{ this.campaignName }}
                    </p>

                    <v-icon class="breadcrumb-arrow-unselected"
                        style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                    <p class="breadcrumb-text" style="margin-top: 2px">Test Report</p>
                    <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
                </v-card-title>
                <div class="size-menu d-flex flex-row justify-start pr-4  w-fit align-center"
                    style="position: fixed; right: 0;">
                    <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
                        <v-hover>
                            <img @click="notificationDialog = true" class="cursor" src="../assets/bell 1.svg">
                        </v-hover>
                    </v-badge>

                    <div>
                        <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
                    </div>
                    <div class="d-flex flex-column d-justify-evenly user-details">
                        <v-card-title class="ma-0 pa-0 elipsis">
                            {{ $store.state.userInfo.name }}
                        </v-card-title>
                        <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                            {{ $store.state.userInfo.email }}
                        </v-card-subtitle>
                    </div>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                                <v-icon class="">mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-btn @click="logout">logout</v-btn>
                    </v-menu>
                </div>
            </v-card>

            <div class="analyticsPage">

                <div class="d-flex flex-row">

                    <!-- <v-icon @click="$emit('close-demo')" class="mr-4 cursor">mdi-arrow-left</v-icon> -->
                    <v-icon @click="$router.back()" class="mr-4 cursor">mdi-arrow-left</v-icon>


                    <h3>{{ this.personalDetails.name }} - Demo Analytics</h3>

                </div>
                
                <div height="136px" class="mt-4">
                    <v-card outlined class="rounded-xl">
                        <v-row class="mx-1" >
                            <v-col
                            sm="4"
                            >
                            <v-row>
                                <v-col sm="2" class="pt-6">
                                   <v-avatar rounded  height="61" width="61"  class="mt-8" v-if="personalDetails?.picture">
                                    <v-img class="rounded-circle" :src="personalDetails?.picture" alt="User Pic"></v-img>
                                </v-avatar>
                                <v-avatar rounded class="mt-8" height="61" width="61" v-else>
                                    <v-img class="rounded-circle" src="../assets/avtar.png" alt="User Pic"></v-img>
                                </v-avatar> 
                                </v-col>
                                <v-col sm="10" class="demo-text mt-8 pt-6">
                                <v-row>
                                    <v-col sm=4>
                                      <p class="text-uppercase user-profile-text">Name: </p>
                                      <p class="text-uppercase user-profile-text">Email Id: 
                                      </p>
                                        <p class="text-uppercase user-profile-text">Phone Number: 
                                        </p>
                                    </v-col>
                                    <v-col sm=8>
                                      <p class="user-details-text text-uppercase">{{ this.personalDetails.name }}</p>
                                      <p v-if="this.personalDetails.email" class="user-details-text text-uppercase elipsis">{{ this.personalDetails.email }}</p>
                                      <p v-else class="user-details-text text-uppercase">-</p>
                                      <p v-if="this.personalDetails.phoneNo" class="user-details-text text-uppercase">{{ this.personalDetails.phoneNo }}</p>
                                      <p v-else class="user-details-text text-uppercase">-</p>  
                                    </v-col>
                                </v-row>
                                    
                                </v-col>
                            </v-row>
                            
                            <!-- <div class="ml-8 flex-1 demo-text">
                                
                            </div> -->
  
                            </v-col>
                            <v-divider
                              vertical
                              class="mt-10 mb-6"
                            ></v-divider>
                            <v-col
                            sm="8"
                            >
                            <span class="ml-2 score-text" >AVERAGE SCORE </span>
                                <v-row>
                                    <v-col
                                    v-for="(demo, key) in cumulativeDemoTest" :key="key"
                                    align="center"
                                    sm="3"
                                    class="px-0 ">
                                    <v-card
                                            class="pa-2 ma-2 rounded-xl"
                                              outlined
                                              tile

                                              
                                            >
                                            <v-progress-circular
                                            class="v-progress-circular__overlay"
                                            :value="demo"
                                            :rotate="-90"
                                            :size="80"
                                            :width="8"
                                            min="0"
                                            max="10"
                                            :color="getCircleColor(demo)">
                                                <span class="small-circular-progress-inner-outer-text">{{ Number.isInteger(demo/10) ? demo/10 : (demo / 10).toFixed(2) ?? 0}}  / 10</span>
                                            </v-progress-circular>
                                            <p class="mt-2 small-circular-progress-inner-outer-text">{{key.replaceAll('_', ' ')}}</p>
                                            </v-card>
                                        
                                    </v-col>      
                                </v-row>  
                            </v-col>
                        </v-row>
                    </v-card>
                </div>

                 
                <div class="lowerDiv">
                    <v-tabs
                      v-model="selectedtabIndex"
                      background-color="#D6D0BD" 
                      color="#000000"
                      active-class="active"
                      grow
                    >
                      <v-tab
                        v-for="(demoTest, index) in cumulativeTestArray"
                        :key="index"
                      >
                        {{ demoTest.name.toUpperCase() }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="selectedtabIndex">
                        <v-tab-item
                        v-for="(demoTest, index) in cumulativeTestArray"
                        :key="index"
                        > 
                        <v-row class="mx-1">
                            <v-col sm="6" class="px-0" >
                                <v-card
                                class="pa-3 rounded-xl mt-4 mx-4" 
                                outlined
                                tile 
                                >
                                    <div class="d-flex align-center"> 
                                        <div>
                                         <video :key="demoTest.videoData?.videoUrl" ref="video" controls width="109"  class="pt-2">
                                        <source :src="demoTest.videoData?.videoUrl" type="video/mp4">
                                        Your browser does not support the video tag.
                                        </video>   
                                        </div>
                                        <div
                                         class="demo-text ">
                                        <div class="d-flex align-center pl-3">
                                            <div>
                                            <p class="text-uppercase user-profile-text">Video Name: </p>
                                            <p class="text-uppercase user-profile-text">Duration:
                                            </p> 
                                            </div>
                                            <div>
                                                <v-tooltip bottom style="z-index: 999">
                                                <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    <p style="max-width: 20vw" class="user-details-text elipsis">{{ demoTest.videoData?.videoName }} </p>
                                                </span>
                                                </template>
                                                <span>{{ demoTest.videoData?.videoName }}</span>
                                            </v-tooltip>
                                              <p class="user-details-text">{{ getTime(demoTest.videoData?.duration)
                                            }} </p>  
                                            </div>
                                        </div>
                                            
                                        </div>

                                    </div>

                                </v-card>
                                <v-card

                                class="pa-2 ma-4 rounded-xl" 
                                outlined
                                tile
                                >
                                <span class="demo-text">Cumulative Graph</span>
                                   <MultiLayerRadarChart  :chartData="demoTest.radarchart" :id="index"/>

                                </v-card>
                            </v-col>
                            <v-col sm="6" class="px-0">
                                <v-card class="ml-2 pt-4">
                                    <span class="demo-text">SCORE</span>
                                    
                                        <v-row >

                                          <v-col
                                            class="px-0"
                                            sm="6"
                                            align="center"
                                            
                                            >
                                             <v-card
                                            class="d-flex justify-center align-center pa-8 ma-3 
                                            rounded-xl mb-0"
                                              outlined
                                              tile
                                              min-height="254px"
                                            >
                                            <div class="d-flex flex-column align-center">
                                            <v-progress-circular
                                            class="v-progress-circular__overlay"
                                            :value=demoTest.knowledgeLevel
                                            :rotate="-90"
                                            :size="124"
                                            :width="18"
                                            min="0"
                                            max="10"
                                            :color="getCircleColor(demoTest.knowledgeLevel)">
                                                <span class="subject-circular-progress-inner-text ">{{
                                                Number.isInteger(demoTest.knowledgeLevel/10) ? demoTest.knowledgeLevel/10 : (demoTest.knowledgeLevel / 10).toFixed(2) ?? 0}}  / 10</span>
                                            </v-progress-circular>
                                            <p class="mt-2 subject-circular-progress-outer-text">Knowledge Score</p>
                                            </div>
                                            </v-card>
                                          </v-col>
                                          <v-col
                                            class="pl-0"
                                            sm="6"
                                            align="center"

                                            >
                                             <v-card
                                            class="pa-8 ma-3 
                                            mb-0 rounded-xl d-flex justify-center align-center"
                                              outlined
                                              tile
                                              min-height="254px"
                                            >
                                            <div class="d-flex flex-column align-center">
                                            <v-progress-circular
                                            class="v-progress-circular__overlay"
                                            :value=demoTest.confidenceLevel
                                            :rotate="-90"
                                            :size="124"
                                            :width="18"
                                            min="0"
                                            max="10"
                                            :color="getCircleColor(demoTest.confidenceLevel)">
                                                <span class="subject-circular-progress-inner-text">{{
                                                Number.isInteger(demoTest.confidenceLevel/10) ? demoTest.confidenceLevel/10 : (demoTest.confidenceLevel / 10).toFixed(2) ?? 0}}  / 10</span>
                                            </v-progress-circular>
                                            <p class="mt-2 subject-circular-progress-outer-text">Confidence Score</p>
                                            </div>
                                            </v-card>
                                          </v-col>
                                          <v-col
                                            class="px-0"
                                            sm="6"
                                            align="center"

                                            >
                                             <v-card
                                            class="pa-8 ma-3 mt-0 rounded-xl d-flex justify-center align-center"
                                              outlined
                                              tile
                                              min-height="254px"
                                            >
                                            <div class="d-flex flex-column align-center">
                                            <v-progress-circular
                                            class="v-progress-circular__overlay"
                                            :value=demoTest.behaviorlLevel
                                            :rotate="-90"
                                            :size="124"
                                            :width="18"
                                            min="0"
                                            max="10"
                                            :color="getCircleColor(demoTest.behaviorlLevel)">
                                                <span class="subject-circular-progress-inner-text ">{{
                                                Number.isInteger(demoTest.behaviorlLevel/10) ? demoTest.behaviorlLevel/10 : (demoTest.behaviorlLevel / 10).toFixed(2) ?? 0}}  / 10</span>
                                            </v-progress-circular>
                                            <p class="mt-2 subject-circular-progress-outer-text">Behavioral Score</p>
                                            </div>
                                            </v-card>
                                          </v-col>
                                          <v-col
                                            class="pl-0"
                                            sm="6"
                                            align="center"

                                            >
                                             <v-card
                                            class="pa-8 ma-3 mt-0 rounded-xl d-flex justify-center align-center"
                                              outlined
                                              tile
                                              min-height="254px"
                                            >
                                            <div class="d-flex flex-column align-center">
                                            <v-progress-circular
                                            class="v-progress-circular__overlay"
                                            :value=demoTest.fluencyLevel
                                            :rotate="-90"
                                            :size="124"
                                            :width="18"
                                            min="0"
                                            max="10"
                                            :color="getCircleColor(demoTest.fluencyLevel)">
                                                <span class="subject-circular-progress-inner-text ">{{
                                                Number.isInteger(demoTest.fluencyLevel/10) ? demoTest.fluencyLevel/10 : (demoTest.fluencyLevel / 10).toFixed(2) ?? 0}}  / 10</span>
                                            </v-progress-circular>
                                            <p class="mt-2 subject-circular-progress-outer-text">Fluency Score</p>
                                            </div>
                                            </v-card>
                                          </v-col>
                                        </v-row>
                                     
                                </v-card>  
                            </v-col> 
                        </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                    
                    
                </div>
           

            </div>

        </div>
    </div>
</template>

<script>
import AuthService from "../services/AuthService";
import ComputerVision from '../components/ComputerVision.vue'
import NlpAudio from '../components/NlpAudio.vue'
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.js";
import CampaignController from '@/controllers/CampaignController';
import MultiLayerRadarChart from "../components/ReportGraphs/MultiLayerRadarChart.vue"

export default {
    data() {
        return {
            preloader: true,
            search: "",
            videoDetails: {},
            selectedtabIndex: 0,
            activeVideoIndex: 0,
            radarChartData: {labels: [], datasets: [] },
            labels:['Behaviourl Level','fluency Level','Confidence Level','knowledge Level'],

            cumulativeDemoTest: {
                "Knowledge_Score": 0,
                "Confidence_Score": 0,
                "Behavioral_Score": 0,
                "Fluency_Score": 0
            },
            
            cumulativeTestArray:[],
            campaignName:"",
            displayNoData:false,
            personalDetails:{
                name:'',
                phoneNo:'',
                email:'',
                picture:''
            }
        }
    },
    components: {
        ComputerVision,
        NlpAudio,
        MultiLayerRadarChart
    },
    async mounted() {
        this.campaignId = this.$route.params.campaignId;
        this.userId = this.$route.params.userId;

        await this.getVideoDetails();
        this.getReportData();
        
        this.getCampaignDetails();
        // this.videoDetails=this.$route.params.videoDetails
        // console.log("videoDetails :", this.$route.params.videoDetails)
    },
    methods: {
        async getCampaignDetails(){
                const campaignRef = doc(db,"campaigns",this.campaignId);
                const campaignSnap = await getDoc(campaignRef);
                const campaignData = campaignSnap.data()
                this.campaignName = campaignData.name;
                return campaignData
            },
        getTime(d) {
            let ans = ""

            if (d) {
                let sec = parseInt(d % 60);
                let min = parseInt(d / 60);
                let hr = parseInt(min / 60);
                min = min % 60;

                if (hr > 0) {
                    ans += `${hr} hrs `
                }
                if (min > 0) {
                    ans += `${min} min `
                }
                if (sec > 0) {
                    ans += `${sec} sec`
                }
            }
            return ans;
        },

        logout() {
            AuthService.logout();
            this.$router.push("/login");
        },
        async getVideoDetails() {
            this.videoDetails = this.$store.state.selectedVideo ? this.$store.state.selectedVideo : {}

            if (Object.keys(this.videoDetails).length === 0) {
                this.videoDetails = await this.setUserDemoVideoDetails()
                const demoVideos = this.videoDetails
                localStorage.setItem("videoInfo", JSON.stringify(demoVideos));
                this.$store.commit("setVideoDetails", demoVideos);
            }
            this.preloader = false
        },
        async getReportData() {
            try {
                
                const response = await CampaignController.getReportData(this.userId, this.campaignId);
                console.log("jhgjgjf :", response.data);
                
                if (response.data?.result) {
                    
                    if(!response.data.result.demoTestChart){
                        this.displayNoData = true;
                    }
                    this.personalDetails.name = response.data?.result?.name;
                    this.personalDetails.email = response.data?.result?.email;
                    this.personalDetails.phoneNo = response.data?.result?.phone;
                    this.personalDetails.picture = response.data?.result?.proctoringPic;
                    this.cumulativeDemoTest.Knowledge_Score = response.data.result.cumulativeDemoTest.knowledgeLevel * 10;
                    
                    this.cumulativeDemoTest.Behavioral_Score = response.data.result.cumulativeDemoTest.behaviorlLevel * 10;
                    this.cumulativeDemoTest.Fluency_Score = response.data.result.cumulativeDemoTest.fluencyLevel * 10;
                    this.cumulativeDemoTest.Confidence_Score = response.data.result.cumulativeDemoTest.confidenceLevel * 10;

                    response.data.result.demoTestChart.forEach(subject => {
                    var radarChartData = {
                        labels: ["Behavioral Score", "Fluency Score", "Confidence Score", "Knowledge Score"],
                        datasets: [{
                            label: 'Demo',
                            data: [subject.behaviorlLevel, subject.fluencyLevel, subject.confidenceLevel, subject.knowledgeLevel],
                            backgroundColor: "rgba(243, 248, 183, 0.8)",
                            borderColor: 'rgba(224, 237, 75, 1)',
                            pointBackgroundColor: 'rgb(255, 99, 132)',
                            pointRadius: 0,
                            pointBorderColor: '#586486',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgb(255, 99, 132)'
                        }]
                    };  
                        let video;
                        console.log('details video',this.videoDetails.demovideos)
                        this.videoDetails.demovideos.forEach(videoObject => {
                            if(videoObject.subject == subject.name){
                                video = videoObject 
                                console.log('video',video)   
                            }
                        });
                        var subjectObj = {
                            name: subject.name,
                            radarchart: radarChartData,
                            videoData : video,
                            behaviorlLevel: subject.behaviorlLevel * 10,
                            fluencyLevel: subject.fluencyLevel * 10,
                            confidenceLevel: subject.confidenceLevel * 10,
                            knowledgeLevel: subject.knowledgeLevel * 10
                        };
                        // Push subject object to cumulativeTestArray
                        this.cumulativeTestArray.push(subjectObj);
                    });
                
                          
                } else {
                    // alert(response.data.error);
                    console.log('response data error...', response.data.error)
                    alert("Something went wrong");
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.preloader = false;
            }
        },
        goBack() {
            if (window.opener) {
                window.close();
            } else {
                this.$router.back();
            }
        },

        loadNextSubject() {
            this.activeVideoIndex++
        },
        loadPrevSubject() {
            this.activeVideoIndex--
        },
        async setUserDemoVideoDetails() {
            const docRef = doc(db, "users", this.userId);
            const docSnap = await getDoc(docRef);
            // console.log("demo video reference..??", videoRef)
            return new Promise((resolve) => {
                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    this.userData = docSnap.data();
                } else {
                    console.log("No such document!");
                    reject("No such document!")
                }
                const videoRef = collection(
                    db,
                    "campaigns",
                    this.campaignId,
                    "audience",
                    this.userId,
                    "demovideos"
                );
                
                const getUserDetailsForCampaign = async () => {
                    const userAudianceRef = doc(db, "campaigns", this.campaignId, "audience", this.userId);
                    const userAudianceSnap = await getDoc(userAudianceRef);
                    const userDetails = userAudianceSnap.data()
                    console.log('userDetails', userDetails.proctoringPic)
                    return userDetails.proctoringPic
                }
                getUserDetailsForCampaign()
                
                onSnapshot(videoRef, async (videoSnapshot) => {
                    this.dataDemoVideos = {
                        name: this.userData.personalInfo.firstName,
                        email: this.userData.personalInfo.contactInfo.email,
                        phoneNumber: this.userData.personalInfo.contactInfo.phoneNumber,
                        proctoringPic: await getUserDetailsForCampaign(),
                        demovideos: []
                    }
                    videoSnapshot.forEach((v) => {
                        const videoData = v.data();
                        const videoId = v.id;
                        this.dataDemoVideos.demovideos.push({
                            videoId: videoId,
                            ...videoData,
                        });
                    });

                    console.log(
                        "data demo videos after video snapshot...??",
                        this.dataDemoVideos
                    );
                    if(this.dataDemoVideos?.demovideos.length===0)  reject(this.dataDemoVideos?.demovideos)
                    resolve(this.dataDemoVideos)
                });
            })

        },
        getCircleColor(value) {

              if (value < 40) {
                return '#FF2600'; 
              } else if (value >= 40 && value < 60) {
                return '#E39236';  
              } else if (value >= 60 && value < 75) {
                return '#97AD39';  
              }else if (value >= 75 && value < 90) {
                return '#5f9C4A';  
              }else {
                return '#56905E'; 
              }
        }
    },
    // computed: {
    //     demovideos() {
    //         console.log(this.videoDetails.demovideos)
    //         return this.videoDetails.demovideos[this.activeVideoIndex];
    //     },
        
    // }
}
</script>
<style scoped>
.subject-circular-progress-inner-text{
    color: black;
    font-size: 18.94px;
    font-weight: 500 !important;
    line-height: 27.43px;
}
.subject-circular-progress-outer-text{
    color: black;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: 22px;
}
.demo-text{
    font-family: "GraphikMedium";
    font-size: 14px;
}
.score-text{
    font-family: "GraphikMedium";
    font-size: 12px;
}
.active {
    color: #000000;
    background-color:  #C9C3B0;
    border-bottom: 3px solid #B2AC88;
}
.small-circular-progress-inner-outer-text{
  color: black;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 15.4px;
}
.user-profile-text{
    font-family: "GraphikMedium";
    font-size: 14px;
    font-weight: 500 !important;
    
}
.user-details-text{
    font-family: "GraphikMedium";
    font-size: 14px;
    font-weight: 400 !important;    
}
.v-progress-circular__overlay {
     stroke-linecap: round;
     
 }
.card {
    width: 300px;
    height: 200px;
    
    /*border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
    }
.mainDiv {
    height: 100%;
    margin-top: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>




