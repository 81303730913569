<template>
    <div v-if="preloader === true">
        <template>
            <v-dialog v-model="preloader" hide-overlay persistent width="300">
                <v-progress-linear indeterminate></v-progress-linear>
            </v-dialog>
        </template>
    </div>

    <div v-else>
        <v-card height="64px" width="100%"
            class="background ml-4 mr-6 my-0 px-4 transparent elevation-0 d-flex flex-row justify-space-between align-center"
            style="
          position: fixed;
          z-index: 200;
          top: 0;
          width: 98%;
          padding-top: 16px;
        ">
            <v-card-title class="pl-0 ml-0">
                <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.push('/campaign')">
                    Campaigns
                </p>
                <v-icon class="breadcrumb-arrow-unselected"
                    style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                <p class="breadcrumb-text-unselected underline-on-hover cursor" @click="$router.back()">
                    {{ campaignName }}
                </p>
                <v-icon class="breadcrumb-arrow-unselected"
                    style="margin-top: 6px !important">mdi-chevron-right</v-icon>
                <p class="breadcrumb-text" style="margin-top: 2px">Test Report</p>
                <v-icon class="breadcrumb-arrow" style="margin-top: 5px !important">mdi-chevron-down</v-icon>
            </v-card-title>
            <div class="size-menu d-flex flex-row justify-start pr-0 w-fit align-center"
                style="position: fixed; right: 0">
                <v-badge color="red" dot right transition="slide-x-transition" class="mr-8">
                    <v-hover>
                        <img @click="notificationDialog = true" class="cursor" width="24px"
                            src="../assets/bell 1.svg" />
                    </v-hover>
                </v-badge>

                <div>
                    <img src="../assets/avtar.png" class="rounded-xl mr-2" width="32" height="32" />
                </div>
                <div class="d-flex flex-column d-justify-evenly user-details">
                    <v-card-title class="ma-0 pa-0 elipsis">
                        {{ $store.state.userInfo.name }}
                    </v-card-title>
                    <v-card-subtitle class="accent--text ma-0 pa-0 elipsis">
                        {{ $store.state.userInfo.email }}
                    </v-card-subtitle>
                </div>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn width="40px" height="40px" color="primary" dark v-bind="attrs" v-on="on" icon>
                            <v-icon class="">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-btn @click="logout">logout</v-btn>
                </v-menu>
            </div>
        </v-card>
        <section class="repUpDiv flex-column gap-12">
            <div class="d-flex pb-2">
                <v-icon @click="$router.back()" class="mr-4 cursor">mdi-arrow-left</v-icon>
                <h3>{{ personalInfo?.firstName }} {{ personalInfo?.lastName }} - Interview Report</h3>
            </div>
            <template>
                <v-card class="mx-auto w-100 pa-4" outlined>
                    <v-container class="bg-surface-variant pa-0 m-width-none">
                        <v-row class="align-center">
                            <v-col>
                                <v-sheet class="border-right ">
                                    <div class="d-flex align-center justify-start">
                                        <v-avatar size="69">
                                            <v-img :src="proctoringPic"></v-img>
                                        </v-avatar>
                                        <ul style="list-style: none;">
                                            <li class="text-body-2 font-weight-bold">{{ personalInfo?.firstName }} {{
        personalInfo?.lastName }}</li>
                                            <li class="text-body-2 font-weight-regular">{{
        personalInfo?.contactInfo?.email }}</li>
                                            <li class="text-body-2 font-weight-regular">{{
        personalInfo?.contactInfo?.phoneNumber }}</li>
                                        </ul>
                                    </div>
                                </v-sheet>
                            </v-col>
                            <v-col>
                                <v-sheet class="border-right">
                                    <div class="d-flex flex-column w-90">                                        
                                        <div>
                                            <span class="text-body-2 font-weight-regular">School - </span>
                                            &nbsp;&nbsp;
                                            <span class="text-body-2 font-weight-bold float-right">{{
        interviewDetails?.interviewSlotDetails?.schoolName }}</span>
                                        </div>
                                        <div>
                                            <span class="text-body-2 font-weight-regular">Current CTC - </span>
                                            <span class="text-body-2 font-weight-bold float-right">{{
        interviewDetails?.candidateDetails?.currentCTC ?? 'NA' }} LPA</span>
                                        </div>
                                        <div>
                                            <span class="text-body-2 font-weight-regular">Expected CTC - </span>
                                            <span class="text-body-2 font-weight-bold float-right">{{
        interviewDetails?.candidateDetails?.expectedCTC }} LPA</span>
                                        </div>
                                    </div>
                                </v-sheet>
                            </v-col>
                            <v-col>
                                <v-sheet class="border-right">
                                    <div class="d-flex flex-column w-90">
                                        <div>
                                            <span class="text-body-2 font-weight-regular">Total exp - </span>
                                            &nbsp;&nbsp;
                                            <span class="text-body-2 font-weight-bold float-right">{{
        interviewDetails?.candidateDetails?.totalExperience?.years }} yrs {{
        interviewDetails?.candidateDetails?.totalExperience?.months }}
                                                mo</span>
                                        </div>
                                        <div>
                                            <span class="text-body-2 font-weight-regular">Relevant Exp - </span>
                                            &nbsp;&nbsp;
                                            <span class="text-body-2 font-weight-bold float-right">{{
        interviewDetails?.candidateDetails?.relevantExperience?.years }} yrs 
                                                {{ interviewDetails?.candidateDetails?.relevantExperience?.months }}
                                                mo</span>
                                        </div>
                                        <div>
                                            <span class="text-body-2 font-weight-regular">Notice Period - </span>
                                            &nbsp;&nbsp;
                                            <span class="text-body-2 font-weight-regular float-right">{{
        interviewDetails?.candidateDetails?.noticePeriod }} days</span>
                                        </div>
                                    </div>
                                </v-sheet>
                            </v-col>
                            <v-col cols="2">
                                <v-sheet class="border-right">
                                    <section
                                        class="score-container d-flex justify-center align-center flex-column py-3 px-4">
                                        <p>
                                            <span class="font-weight-bold"> {{ overAllScore.toFixed(1) }}</span> / <span
                                            class="font-weight-bold" >10</span>

                                        </p>
                                        <span class="text-caption">Overall score</span>
                                    </section>
                                </v-sheet>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </template>

        </section>
        <section class="bottomDiv flex-column">
   <span class="text-body-2 font-weight-bold">Candidate Assessment</span>
        <div class="interview-panels">
            <v-row>
                <v-col cols="6"></v-col>
                <v-col class="d-flex justify-center text-center m-left-10"  cols="2"><span class="interviewer-name" v-if="colVisiblity[2]">    <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                {{interViewFeedback[2].interviewerEmail ? interViewFeedback[2].interviewerEmail : '---' }}
                                            
                                            </span>
                                            </template>
                                            <div > {{interViewFeedback[2].interviewerEmail ? interViewFeedback[2].interviewerEmail : '---' }}</div>
                                        </v-tooltip></span></v-col>
                <v-col class="d-flex justify-center text-center m-left-10"  cols="2"><span class="interviewer-name" v-if="colVisiblity[1]">
                    <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                {{interViewFeedback[1].interviewerEmail ? interViewFeedback[1].interviewerEmail : '---' }}
                                            
                                            </span>
                                            </template>
                                            <div > {{interViewFeedback[1].interviewerEmail ? interViewFeedback[1].interviewerEmail : '---' }}</div>
                                        </v-tooltip></span></v-col>
                <v-col class="d-flex justify-center text-center m-left-10"  cols="2"><span class="interviewer-name" v-if="colVisiblity[0]"> <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                {{interViewFeedback[0].interviewerEmail ? interViewFeedback[0].interviewerEmail : '---' }}
                                            
                                            </span>
                                            </template>
                                            <div > {{interViewFeedback[0].interviewerEmail ? interViewFeedback[0].interviewerEmail : '---' }}</div>
                                        </v-tooltip></span></v-col>
            </v-row>
        </div>
        <div class="larger-bottom">
            <div class="central-table">
                <div class="main-table rounded-xl">           
                        <div class="vertical-menu">
                            <div class="d-flex flex-column">
                                <div>
                                <div
                                    v-for="(data, index) in skills"
                                    :key="index"
                                    @click="selectSkill(data.skillName, index)"
                                    :class="['commonColoredContainer',    selectedSkill != data.skillName
                                    ? findColor(data.skillName)
                                    : findColorForSelected(data.skillName)
                                    ]"
                                 
                                    >
                                    <span class="spanContainer caption">{{
                                    data.skillName
                                    }}</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="data-grid">
                             <v-row v-if="selectedSkill == 'Core Skills'" class="button-toggle-row">
                                <v-col cols="6">
                                        <v-btn-toggle class="px-6"
                                        v-if="coreSkillToggle.length > 1"
                                        v-model="toggleType"
                                        mandatory
                                        dense
                                        rounded
                                    >
                                        <v-btn
                                        v-for="(skillBtn, index) in coreSkillToggle"
                                        class="w-100 skill-btn"
                                        :class="
                                        toggleType == index
                                            ? 'btn-color white--text caption'
                                            : '#000000 btn-color--text caption'
                                        " :key="skillBtn"
                                        >
                                        {{ skillBtn }}
                                        </v-btn>
                                    </v-btn-toggle>
                                    <h3 class="core-subject-title" v-else>{{coreSkillToggle[0]}}</h3   >
                                </v-col>
                    <v-col cols="2"  :class="[ colColors[2]]" ></v-col>
                    <v-col cols="2" :class="[ colColors[1]]" ></v-col>
                    <v-col cols="2"  :class="[ colColors[0]]" ></v-col>
                 
                   
                </v-row>
                            <v-row v-for="(data, index) in displayData" class="mx-h-81" :key="data.title" :class="{ 'first-row': index === 0 && selectedSkill !== 'Core Skills' }" >
                            <v-col cols="6">
                                <p  class="feedback-title">{{data.title}}</p>
                                <p class="feedback-desc">{{data.description}}</p>
                            </v-col>
                            <v-col cols="2" class="feedback-title d-flex justify-center text-center" :class="[ colColors[2]]"><span v-if="colVisiblity[2]">{{data.rating2 > 9 ? data.rating2: `0${data.rating2}` }} / 10</span></v-col>
                            <v-col cols="2" class="feedback-title d-flex justify-center text-center" :class="[ colColors[1]]"><span v-if="colVisiblity[1]">{{data.rating1  > 9 ? data.rating1: `0${data.rating1}` }} / 10</span></v-col>
                            <v-col cols="2" class="feedback-title d-flex justify-center text-center"  :class="[ colColors[0]]"><span v-if="colVisiblity[0]">{{data.rating  > 9 ? data.rating: `0${data.rating}` }} / 10</span></v-col>
                            </v-row>
                                <v-row class="last-row mb-10px" align-end >
                                    <v-col cols="6" class="pl-8 average-text d-flex justify-start align-end">
                                        {{ subjectAverageRatings.name  }} Average
                                    </v-col>
                                    <v-col  cols="2" class="feedback-title justify-center text-center average-text d-flex align-end"  :class="[ colColors[2]]"><span v-if="colVisiblity[2]">{{subjectAverageRatings.avg.avg2 | tofixRating}}</span></v-col>
                                    <v-col  cols="2" class="feedback-title d-flex justify-center text-center align-end average-text"  :class="[ colColors[1]]"><span v-if="colVisiblity[1]">{{ subjectAverageRatings.avg.avg1 | tofixRating}}</span></v-col>
                                    <v-col  cols="2" class="feedback-title d-flex justify-center text-center  align-end average-text" :class="[ colColors[0]]"><span v-if="colVisiblity[0]">{{ subjectAverageRatings.avg.avg0 | tofixRating}}</span></v-col>
                             </v-row>
                        </div>
                </div>
            </div>
        </div>
             <div class="comment-section">
                                  <v-row >
                <v-col cols="6"  class="pl-12 interviewer-name d-flex justify-start align-center" ><p>Comments</p></v-col>
                <v-col class="text-right interviewer-name rounded-lg m-left-5 cursor" cols="2"  :class="[ colColors[2]]" @click="dialogHandler(interViewFeedback[2])">
                    <div class="comment-recommendations"  v-if="colVisiblity[2]" >
                        <p :class="interViewFeedback[2]?.recommendation == 'HOLD' ? 'hold-txt': 'recommend-txt'" >{{interViewFeedback[2].recommendation}}</p>
                        <p  class="interview-remark">{{interViewFeedback[2].remark | strippedContent}}</p> 
                    </div>
                </v-col>
                      <v-col class="text-right interviewer-name rounded-lg m-left-5 cursor"  cols="2"  :class="[ colColors[1]]" @click="dialogHandler(interViewFeedback[1])">
                    <div class="comment-recommendations"  v-if="colVisiblity[1]" >
                        <p :class="interViewFeedback[1]?.recommendation == 'HOLD' ? 'hold-txt': 'recommend-txt'" >{{interViewFeedback[1].recommendation}}</p>
                        <p  class="interview-remark">{{interViewFeedback[1].remark | strippedContent}}</p> 
                    </div>
                </v-col>
                      <v-col class="text-right interviewer-name rounded-lg m-left-5 cursor" cols="2"  :class="[ colColors[0]]" @click="dialogHandler(interViewFeedback[0])">
                    <div class="comment-recommendations"  v-if="colVisiblity[0]" >
                        <p :class="interViewFeedback[0]?.recommendation == 'HOLD' ? 'hold-txt': 'recommend-txt'" >{{interViewFeedback[0].recommendation}}</p>
                        <p  class="interview-remark">{{interViewFeedback[0].remark | strippedContent}}</p> 
                    </div>
                </v-col>
            </v-row>   
                </div>
</section>
    <div v-if="dialogActive">

        <v-dialog v-model="dialogActive"  max-width="366px">
        <v-card fluid  min-height="250px">
          <v-container fluid class="pa-0">
            <v-card-text class="text-center">
                <v-icon size="24" class="icon-aligner" @click="dialogHandler">mdi-close</v-icon>
                <div class="d-flex flex-column justify-space-between">
                    <p class="text-h5 pt-4 pb-4 font-weight-bold">{{dialogContent.recommendation}}</p>
                <p class="text-disabled grey--text text-subtitle-1 remark-color">
                {{dialogContent.remark | strippedContent}}
              </p>
                </div>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
    </div>
</template>

<script>
import AuthService from "../services/AuthService";
import CampaignController from '@/controllers/CampaignController';
import {  doc, getDoc  } from "firebase/firestore";
import { db } from "../firebase.js";

export default {
    data() {
        return {
            toggleType: 0,
            preloader: true,
            campName: "",
            personalInfo: null,
            interviewDetails: null,
            interViewFeedback: null,
            proctoringPic: null,
            selectedSkill: 'Core Skills',
            skills: null,
            selectedSkillIndex: 0,
            coreSkillToggle: [],
            allSubjectScore: [],
            resultMap: {},
            avgMap: {},
            colColors: ['', '',''], 
            colVisiblity: [false, false, false],
            dialogActive: false,
            dialogContent: {},
            campaignName:"",     
        }
    },
    filters: {
    strippedContent: function(string) {
           return string.replace(/<\/?[^>]+>/ig, " "); 
        },
    tofixRating: function(number){
       return number % 1 !== 0 || !Number.isInteger(number) ? number.toFixed(1) : number
    }
},
    computed: {
        overAllScore() {
            let score = 0
            if (this.interViewFeedback !== null) {
                score = this.interViewFeedback?.reduce((total, { avgScore }, _, arr) => total += avgScore / arr.length, 0)
            }
            return score
        },
        displayData() {
            if (this.selectedSkill == 'Core Skills') {
                return this.resultMap['coreSkill'+this.coreSkillToggle[this.toggleType]]
            } else {
                return this.resultMap['skill'+this.selectedSkill]
             }
        },
     subjectAverageRatings() {
         if (this.selectedSkill === 'Core Skills') {
             return {name: this.coreSkillToggle[this.toggleType], avg: this.avgMap['coreSkill'+this.coreSkillToggle[this.toggleType]] };
         } else {   
            return {name:this.selectedSkill, avg: this.avgMap['skill'+this.selectedSkill]};
    }
  }
     
    },
    async mounted() {
        this.campaignId = this.$route.params.campaignId;
        this.userId = this.$route.params.userId;
        await this.getReport();
        this.getCampaignDetails()
       
    },
    methods: {
        async getCampaignDetails(){
                const campaignRef = doc(db,"campaigns",this.campaignId);
                const campaignSnap = await getDoc(campaignRef);
                const campaignData = campaignSnap.data()
                this.campaignName = campaignData.name;
                return campaignData
            },
        dialogHandler(data = '') {
            this.dialogActive = !this.dialogActive;
            this.dialogContent = data
        },
        getColColors() {
            this.interViewFeedback.forEach((item, index) => {
                    this.colColors[index] = item.recommendation == 'HOLD' ? 'hold-bg' : 'recommend-bg'
            })
        },
        getColsVisiblity() {
            this.interViewFeedback.forEach((item, index) => {
                this.colVisiblity[index] = true
            })
        },
          hasDecimal(number) {
            return number % 1 !== 0 || !Number.isInteger(number);
        },
        createAvg(data) {
        for (let key in data) {
            this.getAvgRating(key, data[key])    
            }
        },
        getAvgRating(key, data) {
        let avg ={
            ratingSum:0,
            ratingCount:0,
            rating1Sum:0,
            rating1Count:0,
            rating2Sum:0,
            rating2Count:0
        }
          data.forEach((item)=>{
            let nullArr = [undefined, null, '', NaN]
            if(nullArr.every((nullval)=> item.rating !== nullval)){
                avg.ratingSum += item.rating;
                avg.ratingCount++
            }
                if(nullArr.every((nullval)=> item.rating1 !== nullval)){
                avg.rating1Sum += item.rating1;
                avg.rating1Count++
            }
                if(nullArr.every((nullval)=> item.rating2 !== nullval)){
                avg.rating2Sum += item.rating2;
                avg.rating2Count++
            }
          })
          this.avgMap[key] = {avg0: avg.ratingSum/avg.ratingCount, avg1:avg.rating1Sum/avg.rating1Count, avg2:avg.rating2Sum/avg.rating2Count }
        },
        createtableData(interveiwData) {
            interveiwData.forEach((item, index) => {
                 item.subjects.forEach((coreSubject) => {
                    if (index == 0) {
                        this.resultMap['coreSkill' + coreSubject.subjectName] = coreSubject.feedback;
                    } else {
                        this.pluckRating('coreSkill'+coreSubject.subjectName, coreSubject.feedback, index)
                    }
                })  

                item.skills.forEach((skill) => {
                    if (index == 0 && skill.skillName !== 'Core Skills') {
                        this.resultMap['skill' + skill.skillName] = skill.feedback;
                    } else if(skill.skillName !== 'Core Skills'){
                        this.pluckRating('skill' + skill.skillName, skill.feedback, index)
                    }
                }) 
    })
},
    pluckRating(key, feedback, outerIndex) {
    feedback.forEach((item, index) => {
        this.resultMap[key][index]['rating'+outerIndex] = item.rating
    })

},
        selectSkill(skillName, index) {
        console.log(skillName)
      this.selectedSkill = skillName;
      this.selectedSkillIndex = index;
    },
     findColor(value) {
      var colors = "";
      switch (value) {
        case "Core Skills":
          return "containerCoreSkillLite cursor";
          break;
        case "Pedagogy":
          return "containerPedagigyLite cursor";
          break;
        case "Communication Skills":
          return "containerEnglishLite cursor";
          break;
        case "Digital Literacy":
          return "containerComputerLite cursor";
          break;
        case "Psychometry":
          return "containerPsychrometricLite cursor";
          break;
        default:
          return "containerPsychrometricLite cursor";
      }
    },
    findColorForSelected(value) {
      switch (value) {
        case "Core Skills":
          return "containerCoreSkillHard cursor";
          break;
        case "Pedagogy":
          return "containerPedagigyHard cursor";
          break;
        case "Communication Skills":
          return "containerEnglishHard cursor";
          break;
        case "Digital Literacy":
          return "containerComputerHard cursor";
          break;
        case "Psychometry":
          return "containerPsychrometricHard cursor";
          break;
        default:
          return "containerPsychrometricHard cursor";
      }
    },
        logout() {
            AuthService.logout();
            this.$router.push("/login");
        },
        async getReport() {
            try {
                const response = await CampaignController.getInterviewReport(this.userId, this.campaignId);
                console.log("response_data :", response.data);
                if (response.data?.result) {
                    this.personalInfo = response.data?.result?.personalInfo
                    this.proctoringPic = response.data?.result?.proctoringPic
                    this.interviewDetails = response.data?.result?.interviewDetails
                    this.interViewFeedback = response.data?.result?.interViewFeedback
                    this.createtableData(this.interViewFeedback);
                    this.createAvg(this.resultMap)
                    // this.selectedSkill = this.interViewFeedback[0].skills[0]
                    this.skills = this.interViewFeedback[0].skills
                    this.coreSkillToggle = this.interViewFeedback[0].subjects.map(item => item.subjectName)
                    this.getColsVisiblity()
                     this.getColColors()

                } else {
                    console.log('response data error...', response.data.error)
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.preloader = false;
            }
        }
    }
}
</script>
<style scoped>
.row{
    flex: 0 0 auto;
}

.interviewer-name {
    text-wrap: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.vertical-menu{
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    overflow: hidden;
}
.mb-10px{
    margin-bottom:0px
}
.mx-h-81{
    max-height: 81px;
}
.icon-aligner{
    margin-left: 90%; 
    margin-top: 2%;
}
.m-left-5{
   margin-left: 5px;
}
.m-left-10{
    margin-left: 10px;
}
.button-toggle-row{
  margin-top: 0px;  
}
.remark-color{
    max-height: 12vh;
    overflow: auto;
    color: #666666;
}
.first-row{
    margin-top: 0px;
}
.bottomDiv{
  margin: 10px 12px 4px 32px;

}
.data-grid{
    display: flex;
    flex-direction: column;
   width: 97vw;
   background-color: #FFFFFF;

}
.last-row {
  flex-grow: 1;
}
.recommend-txt{
    color:#4D695B;
    text-align: left;
}
.hold-txt{
    color:#B7842C;
    text-align: left;
}
.hold-bg{
    background-color: rgba(233, 182, 94, 0.24);
}
.recommend-bg{
    background-color: rgba(95, 159, 104, 0.24);
}
.feedback-title{
    font-size: 14px;
    padding-left: 10px;
}
.feedback-desc{
    font-size: 12px;
    color:rgba(0, 0, 0, 0.6);
    padding-left: 10px;
}
.interviewer-name, .recommend-txt, .hold-txt{
    font-size: 14px;
}
.average-text{
    font-size: 14px;
}
.interview-remark{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Adjust the number of lines for your needs */
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
}
.interview-panels{
     width: 100vw;
}
.containerPsychrometricHard, .containerPsychrometricLite, .containerComputerHard, .containerComputerLite, .containerEnglishHard, .containerEnglishLite, .containerCoreSkillLite ,.containerCoreSkillHard,.containerPedagigyHard,  .containerPedagigyLite{
    height: 100px;
    padding: 5px;
}
.main-table{
    display: flex;
}
.central-table{
    margin-top: 10px;
    width: 100vw;
    display: flex;  
}
.gap-16 {
    gap: 16px;
}
.comment-section{
    width: 100vw;
    padding: 15px;
    margin-top: 5px;
}

.border-right {
    border-right: 1px solid #0000001F !important;
}

.score-container {
    background: #06C2702B;
    border: 1px solid #06C270;
    border-radius: 8px;
}

.w-90 {
    width: 90%
}

.col-2 {
    flex: 0 0 12.666667%;
    max-width: 12.6666666667%;
}

.larger-bottom{
    position: relative;
    width: 100%;

}
.m-width-none {
    max-width: none;
}
.skill-btn {
    width: 200px;
}


</style>